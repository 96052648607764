<template>
  <div class="home">
    <h2>What's your birthday?</h2>
    <div>
      <select v-model="month" @change="sendWeeksTotal()">
        <option v-for="i in Object.keys(months)" v-bind:key="i">{{ i }}</option>
      </select>
      <select v-model="day" @change="sendWeeksTotal()">
        <option v-for="i in months[month]" v-bind:key="i">{{ i }}</option>
      </select>
      <select v-model="birthYear" @change="sendWeeksTotal()">
        <option v-for="i in possibleYears()" v-bind:key="i">{{ i }}</option>
      </select>
    </div>
    <div style="margin-bottom: 2%">
      <h2>How long do you expect to live?</h2>
      <select v-model="lifeExpectancy" @change="sendExpentancy()">
        <option v-for="i in possibleAges()" v-bind:key="i">{{ i }}</option>
      </select>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineEmits } from "vue";

const months: Record<string, number> = {
  January: 31,
  February: 28,
  March: 31,
  April: 30,
  May: 31,
  June: 30,
  July: 31,
  August: 31,
  September: 30,
  October: 31,
  November: 30,
  December: 31,
};

const day = ref(1);
const month = ref("January");
const birthYear = ref(1970);
const lifeExpectancy = ref("80 years");

const possibleAges = () => {
  const min = new Date().getFullYear() - birthYear.value;
  const max = 100;
  const agesArray = [];

  for (let age = min; age <= max; age++) {
    agesArray.push(age + " years");
  }
  return agesArray;
};

const possibleYears = () => {
  const startYear = 1924;
  const endYear = 2023;
  const yearsArray = [];

  for (let year = startYear; year <= endYear; year++) {
    yearsArray.push(year);
  }

  return yearsArray;
};

const emit = defineEmits<{
  "update:weeksTotal": [newValue: number];
  "update:expectedAge": [newValue: number];
}>();

const convertYearsToWeeks = (years: string) => {
  const numYears = years.replace(" years", "");
  return Number(numYears) * 52;
};

const sendWeeksTotal = () => {
  emit("update:weeksTotal", getNumberOfWeeksSinceBirth());
};

const sendExpentancy = () => {
  emit("update:expectedAge", convertYearsToWeeks(lifeExpectancy.value));
};

const getNumberOfWeeksSinceBirth = () => {
  const date = getUTCDate();
  const today = new Date();
  const diffTime = Math.abs(today.getTime() - date.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const diffWeeks = Math.floor(diffDays / 7);
  return diffWeeks;
};

const getUTCDate = () => {
  const date = new Date();
  date.setUTCFullYear(birthYear.value);
  date.setUTCMonth(Object.keys(months).indexOf(month.value));
  date.setUTCDate(day.value);
  console.log(date);
  return date;
};
</script>

<script lang="ts">
export default {
  name: "DateSelect",
};
</script>

<style scoped>
.home {
  margin-top: 1%;
}
select {
  font-family: "Arial", sans-serif;
  font-size: 16px;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 5px;
  font-weight: bold;
  color: #fff; /* Dark grey text color for better readability */
  background-color: #333; /* Light grey background for a minimalist look */
  border: none; /* Light border with a rounded corner */
  border-radius: 4px; /* Slightly rounded corners */
  padding: 8px 12px; /* Padding for better spacing */
  width: 200px; /* Set a fixed width for consistency */
  cursor: pointer; /* Pointer cursor on hover */
  transition: border-color 0.3s ease, background-color 0.3s ease; /* Smooth transition effects */
}

/* Style for the select element on focus */
select:focus {
  border-color: #444; /* Highlight border color on focus */
  background-color: #333; /* Change background color to white on focus */
  outline: none; /* Remove default focus outline */
}

/* Style for options inside the select */
option {
  font-weight: normal; /* Normal font weight for options */
  padding: 8px; /* Padding inside options for better spacing */
}

button {
  margin-top: 2%;
  font-family: "Arial", sans-serif; /* Modern sans-serif font */
  font-size: 16px; /* Readable font size */
  font-weight: bold; /* Bold text for emphasis */
  color: #fff; /* White text color for contrast */
  background-color: red; /* Bright blue background color */
  border: none; /* Remove default border */
  border-radius: 4px; /* Slightly rounded corners */
  padding: 10px 20px; /* Adequate padding for better touch */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition effects */
  outline: none; /* Remove default outline */
  text-align: center; /* Center-align text */
  display: inline-flex; /* Flexbox for centering text */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

/* Button hover state */
button:hover {
  background-color: darkred; /* Darker blue on hover */
  transform: scale(1.05); /* Slight scale effect on hover */
}

/* Button active state */
button:active {
  background-color: #004494; /* Even darker blue when pressed */
  transform: scale(1); /* Reset scale effect on active */
}

/* Styles for the paragraph inside the button */
button p {
  margin: 0; /* Remove default margin */
  font-size: inherit; /* Inherit font size from the button */
  line-height: 1; /* Adjust line height for consistency */
}
</style>
