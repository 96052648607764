<template>
  <div class="grid-wrapper">
    <transition-group name="fade" tag="div" class="grid-container">
      <div
        v-for="n in expectedWeeksToLive"
        :key="n"
        class="grid-item"
        :style="[n <= weeks ? { background: 'red' } : { background: '#333' }]"
      ></div>
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps } from "vue";

const props = defineProps({
  weeks: {
    type: Number,
    required: true,
  },
  expectedWeeksToLive: Number,
});
</script>

<script lang="ts">
export default {
  name: "CheckBx",
};
</script>
<style scoped>
.grid-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw; /* Full viewport width */
  padding: 20px; /* Padding around the grid */
  box-sizing: border-box;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(100vw / 120), 1fr));
  grid-template-rows: repeat(auto-fill, minmax(calc(100vw / 120), 1fr));
  gap: 5px; /* Gap between the divs */
  width: calc(100vw - 40px); /* Adjust width for padding */
  padding: 5px; /* Padding around the grid */
  box-sizing: border-box;
  overflow: auto;
}

.grid-item {
  transition: background 2s;
  border-radius: 50%;
  width: calc(100vw / 120); /* Including padding in the size */
  height: calc(100vw / 120); /* Including padding in the size */
  border-color: #ddd;
  border-width: 2px;
}
/* Transition styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s ease;
}

.fade-enter-from, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
