<template>
  <div class="home">
    <h1>How Many Weeks You've Used Up</h1>
    <DateSelect
      @update:weeks-total="(exp) => updateWeeks(exp)"
      @update:expected-age="(exp) => updateExpectedWeeks(exp)"
    />
    <Desc :expectedWeeksToLive="expectedWeeks" />
    <h3>By Mike Gecawicz</h3>
    <CheckBoxes :weeks="numWeeks" :expectedWeeksToLive="expectedWeeks" />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import CheckBoxes from "@/components/CheckBoxes.vue";
import DateSelect from "@/components/DateSelect.vue";
import Desc from "@/components/Desc.vue";
const numWeeks = ref(0);
const expectedWeeks = ref(4160);

const updateWeeks = (weeks: number) => {
  numWeeks.value = weeks;
};

const updateExpectedWeeks = (weeks: number) => {
  expectedWeeks.value = weeks;
};
</script>

<script lang="ts">
export default {
  name: "HomeView",
};
</script>

<style scoped>
h1 {
  font-weight: 700;
  color: red;
  margin-bottom: 1rem;
}
h3 {
  font-size: 0.8rem;
  color: #555;
  text-align: right;
  margin-right: 5%;
  margin-bottom: 0;
  margin-top: 0;
}
</style>
