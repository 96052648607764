<template>
  <div class="desc-box">
    <p class="long-message">
      It can often be easy to forget how quickly time slips us by.<br />
      Trying to express the span of a human life can be challenging in terms of
      days. Howver, when we look at it in terms of weeks, it can be a bit easier
      to grasp. Assuming you live to
      <transition
        name="fade"
        @before-enter="beforeEnter"
        @enter="enter"
        @leave="leave"
      >
        <span :key="convertWeeksToYears()">{{ convertWeeksToYears() }}</span>
      </transition>
      years old, you could be around for
      <transition
        name="fade"
        @before-enter="beforeEnter"
        @enter="enter"
        @leave="leave"
      >
        <span :key="expectedWeeksToLive">{{ expectedWeeksToLive }}</span>
      </transition>
      weeks - now thats a number we can visualize! First, enter your birthdate
      and how long you expect to live. The red dots show the number of weeks you
      have lived so far, while the gray ones represent the weeks you have
      left.<br /><br />
      <span style="color: red">
        What will you do with your time on Earth?
      </span>
    </p>
    <p class="short-message">
      The Red Dots are the weeks you've lived so far. <br />
      The Gray Dots are the weeks you have left. <br />
      <span style="color: red; font-weight: 900">
        What will you do with your time on Earth?
      </span>
    </p>
  </div>
</template>
<script setup lang="ts">
import { defineProps } from "vue";
const props = defineProps({
  expectedWeeksToLive: {
    type: Number,
    required: true,
  },
});
const convertWeeksToYears = () => {
  return props.expectedWeeksToLive / 52;
};
const beforeEnter = (el: HTMLElement) => {
  el.style.opacity = "0";
};
const enter = (el: HTMLElement, done: () => void) => {
  el.offsetHeight; // Trigger reflow
  el.style.transition = "opacity 0.5s ease-in-out";
  el.style.opacity = "1";
  done();
};
const leave = (el: HTMLElement, done: () => void) => {
  el.style.transition = "opacity 0.5s ease-in-out";
  el.style.opacity = "0";
  done();
};
</script>
<script lang="ts">
export default {
  name: "Desc-Box",
};
</script>
<style scoped>
.desc-box {
  margin: 0 auto;
  margin-top: 2%;
  width: 70%;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.short-message {
  display: block;
}
.long-message {
  display: none;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
@media screen and (min-width: 768px) {
  .long-message {
    display: block;
  }
  .short-message {
    display: none;
  }
}
</style>
